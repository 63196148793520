import React from 'react';
import ImgExploreUrl from '../../../images/explore/explore-top.svg';
import ImgFrameUrl from '../../../images/explore/frame.svg';
import styles from './ExploreHero.module.scss';

const ExploreHero = () => {
  return (
    <section className={styles.sectionExploreHero}>
      <div className={styles.heroInner}>
        <div className={styles.heading}>
          <h1>Explore</h1>
        </div>
        <div className={styles.img}>
          <img src={ImgExploreUrl} alt="" />
        </div>
        <div className={styles.desc}>
          <p>
            Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo.
            Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit.
          </p>
        </div>
      </div>
      <div className={styles.heroFrame}>
        <img src={ImgFrameUrl} alt="" />
      </div>
    </section>
  );
};

export default ExploreHero;
