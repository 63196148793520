import React from 'react';
import { Link } from 'gatsby';
import RButton from '../../common/RButton';
import styles from './ExploreWho.module.scss';

const whoItems = [
  {
    short: 'S',
    title: 'Sales',
    desc: 'Find qualified leads, follow up, and convert better using Boost tools.',
  },
  {
    short: 'BD',
    title: 'Business Dev',
    desc: 'Find people integral to your success: from investors and influencers to first clients and team members.',
  },
  {
    short: 'ES',
    title: 'Ecommerce Sellers',
    desc: 'Find qualified leads, follow up, and convert better using Boost tools.',
  },
  {
    short: 'O',
    title: 'Outreach',
    desc:
      'Streamline your marketing by finding bloggers, journalists, influencers, and website owners, all within a single platform.',
  },
  {
    short: 'LG',
    title: 'Lead Generation',
    desc:
      'Reach out to potential clients easier than ever. Multiple search features make it easy to find just the person you need.',
  },
];
const ExploreWho = () => {
  return (
    <section className={styles.sectionExploreWho}>
      <h2 className={styles.whoTitle}>
        Who uses <strong>BOOST?</strong>
      </h2>
      <div className={styles.whoList}>
        {whoItems.map(({ short, title, desc }) => (
          <div key={title} className={styles.whoItem}>
            <div className={styles.whoItemInner}>
              <div className={styles.whoHeading}>
                <div className={styles.whoShort}>{short}</div>
                <div className={styles.whoItemTitle}>{title}</div>
              </div>
              <div className={styles.whoDesc}>{desc}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.action}>
        <span className={styles.actionTitle}>Check out our</span>
        <Link to="/pricing">
          <RButton color="secondary" btnSize={50} rSize={40} iconSize={7} iconType={1} btnText="Pricing plans" />
        </Link>
      </div>
    </section>
  );
};

export default ExploreWho;
