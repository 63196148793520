import React from 'react';
import styles from './ExploreBackgrounds.module.scss';

const ExploreBackgrounds = () => (
  <div className={styles.exploreBackgrounds}>
    <div className={styles.exploreBg1} />
    <div className={styles.exploreBg2} />
    <div className={styles.exploreBg3} />
    <div className={styles.exploreBg4} />
  </div>
);

export default ExploreBackgrounds;
