import React from 'react';
import RButton from '../../common/RButton';
import styles from './ExploreFeatures.module.scss';
import OkIconUrl from '../../../images/icons/ok.svg';
import ExploreImg1Url from '../../../images/explore/explore-1.svg';
import ExploreImg2Url from '../../../images/explore/explore-2.svg';
import ExploreImg3Url from '../../../images/explore/explore-3.svg';
import ExploreImg4Url from '../../../images/explore/explore-4.svg';
import { signUpUrl } from '../../../config/settings';

const ExploreFeatures = () => {
  return (
    <section className={styles.sectionExploreFeatures}>
      <div className={styles.featureList}>
        <div className={styles.feature}>
          <div className={styles.featureInfo}>
            <h2 className={styles.featureTitle}>
              The{' '}
              <strong>
                Businesses
                <br /> data list
              </strong>
            </h2>
            <div className={styles.featureDesc}>
              <ul className={styles.featureList}>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>We also have emails that are manualy checked!</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>All of our emails are verified! (Zero bounce)</span>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.featureImg}>
            <img src={ExploreImg1Url} alt="" />
          </div>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureInfo}>
            <h2 className={styles.featureTitle}>
              The <strong>Email campaign</strong>
            </h2>
            <div className={styles.featureDesc}>
              <ul className={styles.featureList}>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>We also have emails that are manualy checked!</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>All of our emails are verified! (Zero bounce)</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem</span>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.featureImg}>
            <img src={ExploreImg2Url} alt="" />
          </div>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureInfo}>
            <h2 className={styles.featureTitle}>
              The <strong>Statistics</strong>
            </h2>
            <div className={styles.featureDesc}>
              <ul className={styles.featureList}>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>We also have emails that are manualy checked!</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>All of our emails are verified! (Zero bounce)</span>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.featureImg}>
            <img src={ExploreImg3Url} alt="" />
          </div>
        </div>
        <div className={styles.feature}>
          <div className={styles.featureInfo}>
            <h2 className={styles.featureTitle}>
              Your <strong>Growth</strong>
            </h2>
            <div className={styles.featureDesc}>
              <ul className={styles.featureList}>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>We also have emails that are manualy checked!</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem</span>
                </li>
                <li>
                  <img className={styles.icon} src={OkIconUrl} alt="" />
                  <span className={styles.text}>All of our emails are verified! (Zero bounce)</span>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.featureImg}>
            <img src={ExploreImg4Url} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <a href={signUpUrl}>
          <RButton color="secondary" btnSize={50} rSize={40} iconSize={7} iconType={1} btnText="Try it free" />
        </a>
      </div>
    </section>
  );
};

export default ExploreFeatures;
