import React from 'react';
import Page from '../components/layout/Page';
import ExploreHero from '../components/layout/explore/ExploreHero';
import ExploreFeatures from '../components/layout/explore/ExploreFeatures';
import ExploreWho from '../components/layout/explore/ExploreWho';
import ExploreBackgrounds from '../components/layout/explore/ExploreBackgrounds';

const ExplorePage = () => (
  <Page title="Explore">
    <ExploreHero />
    <ExploreFeatures />
    <ExploreWho />
    <ExploreBackgrounds />
  </Page>
);

export default ExplorePage;
